import React from "react";
import { Form, notification } from "antd";
import { BaseFormTitle } from "@/components/common/forms/components/BaseFormTitle/BaseFormTitle";
import { BaseFormItem } from "@/components/common/forms/components/BaseFormItem/BaseFormItem";
import { BaseFormList } from "@/components/common/forms/components/BaseFormList/BaseFormList";
import { nError } from "@/controllers/NotificationConfig";

export const BaseForm = ({ onFinishFailed, layout = "vertical", ...props }) => {
  const [notifi, contextHolder] = notification.useNotification();
  const onFinishFailedDefault = (error) => {
    nError(notifi, {
      message: "Error",
      description: error.errorFields[0].errors,
    });
  };

  return (
    <>
      <Form
        onFinishFailed={onFinishFailed || onFinishFailedDefault}
        layout={layout}
        {...props}
      />
      {contextHolder}
    </>
  );
};

BaseForm.Title = BaseFormTitle;
BaseForm.Item = BaseFormItem;
BaseForm.List = BaseFormList;
BaseForm.useForm = Form.useForm;
BaseForm.useWatch = Form.useWatch;
BaseForm.Provider = Form.Provider;
