import React from "react";
import styled from "styled-components";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  StopFilled,
} from "@ant-design/icons";

const IconWrapper = styled.div`
  font-size: ${(props) => (props.$isOnlyTitle ? "2rem" : "3rem")};
  line-height: 2rem;
`;

const EmptyDescription = styled.div`
  margin-top: -0.75rem;
`;

const nSuccess = (instance, config) => {
  instance?.success({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <CheckCircleFilled className="success-icon" />
      </IconWrapper>
    ),
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    className: config.description ? "" : "notification-without-description",
  });
};

const nInfo = (instance, config) => {
  instance?.info({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <InfoCircleFilled className="info-icon" />
      </IconWrapper>
    ),
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    className: config.description ? "" : "notification-without-description",
  });
};

const nWarning = (instance, config) => {
  instance?.warning({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <ExclamationCircleFilled className="warning-icon" />
      </IconWrapper>
    ),
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    className: config.description ? "" : "notification-without-description",
  });
};

const nError = (instance, config) => {
  instance?.error({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <StopFilled className="error-icon" />
      </IconWrapper>
    ),
    message: (
      <div className={`title ${!config.description && `title-only`}`}>
        {config.message}
      </div>
    ),
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    className: config.description ? "" : "notification-without-description",
  });
};

export { nSuccess, nInfo, nWarning, nError };
