import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const token = useSelector((state) => state?.auth?.token)
  return token ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
