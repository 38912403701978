import React from "react";
import CheckEmailForm from "@/components/auth/CheckEmailForm/CheckEmailForm";
import { PageTitle } from "@/components/common/PageTitle/PageTitle";

const CheckEmail = () => {
  return (
    <>
      <PageTitle />
      <CheckEmailForm />
    </>
  );
};

export default CheckEmail;
