import httpApi from '@/api/http.api';

const login = (data) => {
  return httpApi.post(`/v1/users/login`, data);
};

const userInfo = (data) => {
  return httpApi.get(`/v1/users/get-user-info`, data);
};

const logOut = () => {
  return httpApi.get(`/v1/users/revoke-refresh-token`);
};

const verifyCaptcha = (data) => {
  return httpApi.post(`/v1/users/verify-captcha`, data);
};

export { login, userInfo, logOut, verifyCaptcha };