import { isRejectedWithValue } from "@reduxjs/toolkit";
import { nError } from "@/controllers/NotificationConfig";
import { notification } from "antd";
/**
 * Log a warning and show a toast!
 */
export const errorLoggingMiddleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    notification.error(nError({ message: action.payload }));
  }

  return next(action);
};
