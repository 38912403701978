import React from 'react'
import logo from '@/assets/icons/logo.svg';
import * as S from './Logo.style';

const Logo = () => {
  return (
    <S.LogoNav to="/">
        <img src={logo} alt="Rocket" width={36} height={36} />
        <S.LogoText>Rocket Admin</S.LogoText>
    </S.LogoNav>
  )
}

export default Logo