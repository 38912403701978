import React, { useEffect, useState, useMemo } from "react";
import { Layout, Menu, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { commonMenu, permissionMenu } from "@/menu-items";
const { Sider } = Layout;

const MainSider = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(
    "/dashboard/financial/earning"
  );
  const [openKeys, setOpenKeys] = useState([]);
  const user = useSelector((state) => state?.user?.user);
  const navigate = useNavigate();

  const menu = useMemo(() => {
    if (user?.role?.includes("admin")) {
      return permissionMenu;
    }
    return commonMenu;
  }, [user?.role]);

  const allOpenKeys = useMemo(
    () =>
      menu?.reduce((pre, cur) => {
        return pre.concat(cur?.children);
      }, []),
    [menu]
  );

  useEffect(() => {
    const index = pathname.lastIndexOf("/");
    const key = index === 0 ? pathname : pathname.slice(0, index);
    const openKeyItem = allOpenKeys?.find((ele) => key.includes(ele.key));
    if (!openKeyItem?.key) {
      setOpenKeys(["/dashboard/financial"]);
      setSelectedKeys("/dashboard/financial/earning");
      return;
    }
    setOpenKeys((keys) => {
      if (!keys.includes(openKeyItem.key) && keys.length === 0) {
        keys.push(openKeyItem.key);
      }
      return keys;
    });
    setSelectedKeys((cur) => {
      if (pathname.includes(cur)) {
        return cur;
      }
      return openKeyItem?.children
        ?.map((ele) => ele.key)
        .find((e) => pathname.includes(e));
    });
  }, [pathname, allOpenKeys]);

  const onClick = (e) => {
    navigate(e.key);
  };

  const onOpenChange = (arr) => {
    setOpenKeys(arr);
  };

  return (
    <Sider
      width={260}
      style={{
        background: colorBgContainer,
        overflow: "auto"
      }}
    >
      <Menu
        mode="inline"
        selectedKeys={[selectedKeys]}
        onClick={onClick}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{
          width: 260,
        }}
        items={menu}
      />
    </Sider>
  );
};

export default MainSider;
