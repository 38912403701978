import React from 'react';
import { Helmet } from 'react-helmet-async';

export const PageTitle = () => {
  return (
    <Helmet>
      <title>Rocket Admin Panel</title>
    </Helmet>
  );
};
