import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

const { Content } = Layout;

const MainContent = () => {
  return (
    <Layout id="main-layout-content">
      <Content style={{ margin: 0 }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default MainContent;
