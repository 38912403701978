import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import { usePermission } from '@/hooks/usePermission'
import MainContainer from "@/components/layout/container/MainContainer";

const PermissionAuth = () => {
  const navigate = useNavigate();
  const { isPermission } = usePermission();
  const toHome = () => navigate("/dashboard/financial/earning");
  
  if (isPermission) {
    return <Outlet />;
  }

  return (
    <MainContainer grayMode>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={toHome}>
            Back Home
          </Button>
        }
      />
    </MainContainer>
  );
};

export default PermissionAuth;
