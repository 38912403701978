import React from "react";
import MainHeader from "../MainHeader";
import MainSider from "../MainSider";
import MainContent from "../MainContent";
import * as S from './MainLayout.styles';

const MainLayout = () => {
  return (
    <S.LayoutMaster>
      <MainHeader />
      <S.LayoutMain>
        <MainSider />
        <MainContent />
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};
export default MainLayout;
