import styled from "styled-components";
import { Link } from "react-router-dom";

export const LogoNav = styled(Link)`
  display: flex;
  align-items: center;
`;

export const LogoText = styled.span`
  margin: 0 0.4375rem;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--white);
`;
