import { Layout, Col } from "antd";
import styled from "styled-components";
const { Header } = Layout;

export const MainHeader = styled(Header)`
  background-color: var(--header-background-color);
  color: var(--text-sider-secondary-color);
  padding: 0 16px;
`;

export const ItemCol = styled(Col)`
  text-align: center;
`;
