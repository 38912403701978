
import nightModeReducer from "@/store/slices/nightModeSlice";
import themeReducer from "@/store/slices/themeSlice";
import authSlice from "./authSlice";
import userSlice from "./userSlice";

const slices = {
  nightMode: nightModeReducer,
  theme: themeReducer,
  auth: authSlice,
  user: userSlice,
};
export default slices;
