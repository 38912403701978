import React, { useState } from "react";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseForm } from "@/components/common/forms/BaseForm/BaseForm";
import { nError } from "@/controllers/NotificationConfig";
import * as Auth from "@/components/layout/AuthLayout/AuthLayout.styles";
import * as S from "./ForgotPasswordForm.styles";
import { doResetPassword } from "@/store/slices/authSlice";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const initVal = { email: state.email };
  const [isLoading, setLoading] = useState(false);
  const [notifi, contextHolder] = notification.useNotification();

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(doResetPassword(values))
      .unwrap()
      .then(() => {
        navigate("/auth/check-email");
      })
      .catch((err) => {
        nError(notifi, { message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initVal}
      >
        <Auth.FormTitle>Forgot Password?</Auth.FormTitle>
        <S.ForgotPasswordDes>
          Enter the email associated with your account.
        </S.ForgotPasswordDes>
        <Auth.FormItem
          name="email"
          label="email"
          rules={[
            {
              required: true,
              message: "Please enter a valid email address",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Auth.FormInput placeholder="Email Address" />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            SUBMIT
          </Auth.SubmitButton>
        </BaseForm.Item>
        {contextHolder}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
