import React from "react";
import { PageTitle } from "@/components/common/PageTitle/PageTitle";
import { ForgotPassword } from "@/components/auth/ForgotPasswordForm/ForgotPasswordForm";

const ForgotPasswordPage = () => {
  return (
    <>
      <PageTitle />
      <ForgotPassword />
    </>
  );
};

export default ForgotPasswordPage;
