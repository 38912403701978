import styled from 'styled-components';
import { FormTitle } from '@/components/layout/AuthLayout/AuthLayout.styles';
import { FONT_SIZE, FONT_WEIGHT, media } from '@/styles/themes/constants';

export const Title = styled(FormTitle)`
  margin-bottom: 1.875rem;
`;

export const ForgotPasswordDes = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  
  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
    font-weight: ${FONT_WEIGHT.bold};
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.75rem;
    font-weight: ${FONT_WEIGHT.regular};
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.875rem;
  }
`;
