import React from "react";
import styled from "styled-components";

const SpinnerOriginal = ({ className }) => {
  const colors = ["#0047ab", "#5d3fd3", "#663399", "#9370db"];

  return (
    <svg
      className={className}
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="pl__ring1" cx="34" cy="100" r="6" fill={colors[0]} />
      <circle className="pl__ring2" cx="54" cy="100" r="6" fill={colors[1]} />
      <circle className="pl__ring3" cx="74" cy="100" r="6" fill={colors[2]} />
      <circle className="pl__ring4" cx="94" cy="100" r="6" fill={colors[3]} />
    </svg>
  );
};

export const GlobalSpinner = styled(SpinnerOriginal)`
  width: ${(props) => props.size || "6em"};
  height: ${(props) => props.size || "6em"};

  .pl__ring1 {
    animation: waveDotEffect 2s linear infinite;
    animation-delay: 0.1s;
  }
  .pl__ring2 {
    animation: waveDotEffect 2s linear infinite;
    animation-delay: 0.2s;
  }
  .pl__ring3 {
    animation: waveDotEffect 2s linear infinite;
    animation-delay: 0.3s;
  }
  .pl__ring4 {
    animation: waveDotEffect 2s linear infinite;
    animation-delay: 0.6s;
  }

  /* Animations */
  @keyframes waveDotEffect {
    0% {
      transform: translateY(0);
      opacity: 0.5;
    }
    25% {
      transform: translateY(-80px);
      opacity: 1;
    }
    50% {
      transform: translateY(0);
    }
  }
`;
