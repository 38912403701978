import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CheckEmail from "@/pages/CheckEmail";
import ForgotPassword from "@/pages/ForgotPassword";
import Login from "@/pages/Login";
import PermissionAuth from "@/routes/PermissionAuth";
import RequireAuth from "@/routes/RequireAuth";
import PageNotFound from "../pages/PageNotFound";
import { withLoading } from "@/hocs/withLoading.hoc";
import MainLayout from "@/components/layout/main/MainLayout";

const AuthLayout = React.lazy(() =>
  import("@/components/layout/AuthLayout/AuthLayout")
);
const Logout = React.lazy(() => import("./Logout"));
// dashboard
const EarningsPage = React.lazy(() =>
  import("@/pages/dashboard/earnings/Earnings")
);
// extra
const AdminPage = React.lazy(() => import("@/pages/extra/admin/admin"));
const AddAdminPage = React.lazy(() =>
  import("@/pages/extra/admin/admin/editAdmin/AddAdmin")
);
const EditAdminPage = React.lazy(() =>
  import("@/pages/extra/admin/admin/editAdmin/EditAdmin")
);
const SettingPage = React.lazy(() => import("@/pages/extra/settings/settings"));
// manage-accounts
const BrandPage = React.lazy(() => import("@/pages/manage/accounts/brands"));
const ShopDetailsPage = React.lazy(() =>
  import("@/pages/manage/accounts/brands/details/ShopDetails")
);
const ShippingPage = React.lazy(() =>
  import("@/pages/manage/accounts/shipping/ShippingList")
);
const CategoryPage = React.lazy(() =>
  import("@/pages/manage/accounts/categories")
);
const OrderPage = React.lazy(() => import("@/pages/manage/accounts/orders"));
const OrderDetailsPage = React.lazy(() =>
  import("@/pages/manage/accounts/orders/details/OrderDetails")
);
const PostPage = React.lazy(() => import("@/pages/manage/accounts/posts"));
const AddPostPage = React.lazy(() =>
  import("@/pages/manage/accounts/posts/details/AddPost")
);
const EditPostPage = React.lazy(() =>
  import("@/pages/manage/accounts/posts/details/EditPost")
);
const ProductPage = React.lazy(() => import("@/pages/manage/accounts/product"));
const ProductDetailsPage = React.lazy(() =>
  import("@/pages/manage/accounts/product/details/ProductDetails")
);
const ProfilePage = React.lazy(() =>
  import("@/pages/manage/accounts/profiles")
);
const ProfileDetailsPage = React.lazy(() =>
  import("@/pages/manage/accounts/profiles/details/ProfileDetails")
);
const AddProfilePage = React.lazy(() =>
  import("@/pages/manage/accounts/profiles/editProfile/AddProfile")
);
const EditProfilePage = React.lazy(() =>
  import("@/pages/manage/accounts/profiles/editProfile/EditProfile")
);
const UserPage = React.lazy(() => import("@/pages/manage/accounts/users"));
const UserDetailsPage = React.lazy(() =>
  import("@/pages/manage/accounts/users/details/UserDetails")
);
const UserOrdersPage = React.lazy(() =>
  import("@/pages/manage/accounts/users/orders/UserOrders")
);
const UserBillingAddressPage = React.lazy(() =>
  import("@/pages/manage/accounts/users/address/BillingAddress")
);
const UserShippingAddressPage = React.lazy(() =>
  import("@/pages/manage/accounts/users/address/ShippingAddress")
);
const WaitingListPage = React.lazy(() =>
  import("@/pages/manage/accounts/waiting/WaitingList")
);
// manage-reported
const CommentPage = React.lazy(() =>
  import("@/pages/manage/reported/comments")
);
// manage-commission
const CommissionTotalPage = React.lazy(() =>
  import("@/pages/manage/commission/total")
);
const CommissionTransactionPage = React.lazy(() =>
  import("@/pages/manage/commission/transaction")
);
const CommissionTransactionDetailsPage = React.lazy(() =>
  import("@/pages/manage/commission/transaction/details/TransactionDetails")
);
const UserCommissionPage = React.lazy(() =>
  import("@/pages/manage/commission/history/UserCommissionList")
);

// dashboard
const Earnings = withLoading(EarningsPage);
// extra
const Admin = withLoading(AdminPage);
const AddAdmin = withLoading(AddAdminPage);
const EditAdmin = withLoading(EditAdminPage);
const Settings = withLoading(SettingPage);
// manage-accounts
const Brands = withLoading(BrandPage);
const ShopDetails = withLoading(ShopDetailsPage);
const Shipping = withLoading(ShippingPage);
const Categories = withLoading(CategoryPage);
const Orders = withLoading(OrderPage);
const OrderDetails = withLoading(OrderDetailsPage);
const Posts = withLoading(PostPage);
const AddPost = withLoading(AddPostPage);
const EditPost = withLoading(EditPostPage);
const Products = withLoading(ProductPage);
const ProductDetails = withLoading(ProductDetailsPage);
const Profiles = withLoading(ProfilePage);
const ProfileDetails = withLoading(ProfileDetailsPage);
const AddProfiles = withLoading(AddProfilePage);
const EditProfile = withLoading(EditProfilePage);
const Users = withLoading(UserPage);
const UsersDetails = withLoading(UserDetailsPage);
const UserOrders = withLoading(UserOrdersPage);
const UserBillingAddress = withLoading(UserBillingAddressPage);
const UserShippingAddress = withLoading(UserShippingAddressPage);
const WaitingList = withLoading(WaitingListPage);
// manage-reported
const Comments = withLoading(CommentPage);
// manage-commission
const CommissionTotal = withLoading(CommissionTotalPage);
const CommissionTransaction = withLoading(CommissionTransactionPage);
const CommissionTransactionDetails = withLoading(
  CommissionTransactionDetailsPage
);
const UserCommission = withLoading(UserCommissionPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const AppRouter = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={protectedLayout}>
          <Route index element={<Earnings />} />
          <Route path="dashboard">
            <Route path="account-manage">
              <Route path="user" element={<Users />} />
              <Route path="user/:id" element={<UsersDetails />} />
              <Route path="user/order/:id" element={<UserOrders />} />
              <Route
                path="user/shipping-addresses/:id"
                element={<UserShippingAddress />}
              />
              <Route
                path="user/billing-addresses/:id"
                element={<UserBillingAddress />}
              />
              <Route path="profile" element={<Profiles />} />
              <Route path="profile/:id" element={<ProfileDetails />} />
              <Route element={<PermissionAuth />}>
                <Route path="profile/add" element={<AddProfiles />} />
              </Route>
              <Route element={<PermissionAuth />}>
                <Route path="profile/edit/:id" element={<EditProfile />} />
              </Route>
              <Route path="waiting-list" element={<WaitingList />} />
            </Route>
            <Route path="financial">
              <Route path="earning" element={<Earnings />} />
              <Route element={<PermissionAuth />}>
                <Route path="commission" element={<CommissionTotal />} />
                <Route
                  path="commission/transaction"
                  element={<CommissionTransaction />}
                />
                <Route
                  path="commission/transaction/:id"
                  element={<CommissionTransactionDetails />}
                />
                <Route
                  path="commission/user/:id"
                  element={<UserCommission />}
                />
              </Route>
            </Route>
            <Route path="content-manage">
              <Route path="shop" element={<Brands />} />
              <Route path="shop/:id" element={<ShopDetails />} />
              <Route path="shipping" element={<Shipping />} />
              <Route path="post" element={<Posts />} />
              <Route path="post/add/:id" element={<AddPost />} />
              <Route path="post/edit/:id/:profileId" element={<EditPost />} />
              <Route path="products" element={<Products />} />
              <Route path="products/:id" element={<ProductDetails />} />
              <Route path="category" element={<Categories />} />
              <Route path="comment" element={<Comments />} />
            </Route>
            <Route path="order-manage">
              <Route path="order" element={<Orders />} />
              <Route path="order/:id/:profileId" element={<OrderDetails />} />
            </Route>
          </Route>
          <Route path="admin/manage-list" element={<Admin />} />
          <Route path="admin/manage-list/creat-admin" element={<AddAdmin />} />
          <Route path="admin/manage-list/:id" element={<EditAdmin />} />
          <Route path="setting" element={<Settings />} />
        </Route>
        <Route path="auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="check-email" element={<CheckEmail />} />
        </Route>
        <Route path="404" element={<PageNotFound />} />
        <Route path="logout" element={<LogoutFallback />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
