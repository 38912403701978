import styled from "styled-components";
import { Layout } from "antd";

export const LayoutMaster = styled(Layout)`
  height: 100vh;
`;

export const LayoutMain = styled(Layout)`
  margin-left: unset;
`;
