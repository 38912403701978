import React, { useMemo, useState } from "react";
import { Breadcrumb, Button, Result, Row, Col } from "antd";
import { PageTitle } from "@/components/common/PageTitle/PageTitle";
import { useLocation } from "react-router-dom";
import { firstUpperCase } from "@/utils/utils";
import styled from "styled-components";
import MainFooter from "@/components/layout/main/MainFooter";

const Container = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  background-color: var(--background-color);
  color: var(--text-main-color);
  padding: 10px 16px;
  min-height: calc(100% - 146px);
`;

const ScrollView = styled.div`
  height: 100%;
  overflow: auto;
  padding: 0 1rem;
`;

const grayModeStyle = {
  backgroundColor: "var(--primary1-color)",
  padding: 0,
};

const MainContainer = ({
  style,
  grayMode,
  children,
  pathName,
  rightNode,
  isError,
  reload,
  hideFooter,
}) => {
  const location = useLocation();
  const [isReload, setIsReload] = useState(false);
  const path = pathName ? pathName : location?.pathname;
  const breadcrumbContent = useMemo(() => {
    const pathItems = path?.split("/").reduce((pre, cur) => {
      if (!cur) {
        return pre;
      }
      const item = cur
        .split("-")
        .map((ele) => firstUpperCase(ele))
        .join(" ");
      if (item === 'Dashboard') {
        pre.push({ title: item, href: '/' });
      } else if (item === 'Products') {
        pre.push({ title: item, href: '/dashboard/content-manage/products' });
      } else if (item === 'Shops') {
        pre.push({ title: item, href: '/dashboard/content-manage/shop' });
      } else if (item === 'Manage List' && path === 'dashboard/manage-list/edit-post') {
        pre.push({ title: item, href: '/dashboard/content-manage/post' });
      } else if (item === 'User') {
        pre.push({ title: item, href: '/dashboard/account-manage/user' });
      } else if (item === 'Manage Profiles') {
        pre.push({ title: item, href: '/dashboard/account-manage/profile' });
      } else if (item === 'Shipping') {
        pre.push({ title: item, href: '/dashboard/content-manage/shipping' });
      } else if (item === 'Post') {
        pre.push({ title: item, href: '/dashboard/content-manage/post' });
      } else if (item === 'Shop') {
        pre.push({ title: item, href: '/dashboard/content-manage/shop' });
      } else if (item === 'Order') {
        pre.push({ title: item, href: '/dashboard/order-manage/order' });
      } else if (item === 'Profile') {
        pre.push({ title: item, href: '/dashboard/account-manage/profile' });
      } else if (item === 'Commission') {
        pre.push({ title: item, href: '/dashboard/financial/commission' });
      } else if (item === 'Content Manage' || item === 'Order Manage' || item === 'Account Manage' || item === 'Financial') {
        //
      } else if (item === 'Transaction' && path.indexOf('/dashboard/financial/commission/transaction/') >= 0) {
        pre.push({ title: item, href: '/dashboard/financial/commission/transaction' });
      } else {
        pre.push({ title: item });
      }
      return pre;
    }, []);
    return pathItems;
  }, [path]);

  const curStyle = useMemo(
    () =>
      grayMode
        ? {
            ...grayModeStyle,
            ...style,
          }
        : style,
    [grayMode, style]
  );

  const toReload = async () => {
    if (reload) {
      setIsReload(true);
      reload(() => setIsReload(false));
    }
  };

  const errorResult = (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button loading={isReload} onClick={() => toReload()}>
          Try Again
        </Button>
      }
    />
  );

  return (
    <ScrollView>
      <PageTitle />
      <Row align="middle" justify="space-between">
        <Col>
          <Breadcrumb style={{ margin: "16px 0" }} items={breadcrumbContent} />
        </Col>
        <Col>{rightNode}</Col>
      </Row>
      <Container id="main-container" style={curStyle}>
        {isError ? errorResult : children}
      </Container>
      {hideFooter ? null : <MainFooter />}
    </ScrollView>
  );
};

export default MainContainer;
