const testUser = {
  id: 1,
  firstName: "Ambrose",
  lastName: "Johnson",
  imgUrl: null,
  userName: "Ambrose",
  email: {
    name: "johnson@rocket.shop",
    verified: true,
  },
  phone: {
    number: "+1811234567",
    verified: true,
  },
  sex: "male",
  birthday: "01/26/2022",
  lang: "en",
  country: "GB",
  city: "London",
  address1: "",
  zipcode: 5211,
  website: "",
  socials: {
    twitter: "",
    facebook: "",
    linkedin: "",
  },
};

export const persistToken = (token) => {
  localStorage.setItem("adminToken", token);
};

export const readToken = () => {
  return localStorage.getItem("adminToken") || "";
};

export const persistRefreshToken = (token) => {
  localStorage.setItem("adminRefreshToken", token);
};

export const readRefreshToken = (token) => 
  localStorage.getItem("adminRefreshToken", token);

export const persistAuthUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const readAuthUser = (user) => 
  localStorage.getItem("user", JSON.stringify(user));

export const persistUser = (user) => {
  localStorage.setItem("userInfo", JSON.stringify(user));
};

export const readUser = () => {
  const userStr = localStorage.getItem("userInfo");

  return userStr ? JSON.parse(userStr) : testUser;
};

export const deleteToken = () => localStorage.removeItem("adminToken");
export const deleteUser = () => localStorage.removeItem("userInfo");
export const deleteAuthUser = () => localStorage.removeItem("user");
export const deleteRefreshToken = () => localStorage.removeItem("adminRefreshToken");
