import _ from "lodash";
import parsePhoneNumber from "libphonenumber-js";

export const hexToRGB = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

export const firstUpperCase = (str) => {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const msToH = (ms) => Math.floor(ms / 3600000);

export const defineColorBySeverity = (severity, rgb = false) => {
  const postfix = rgb ? "rgb-color" : "color";
  switch (severity) {
    case "error":
    case "warning":
    case "success":
      return `var(--${severity}-${postfix})`;
    case "info":
    default:
      return `var(--primary-${postfix})`;
  }
};

export const _getValue = (object, path, defaultValue = null) => {
  const val = {
    value: "",
    result: false,
  };
  try {
    const getResult = _.get(object, path, defaultValue);
    if (getResult === null) {
      return val;
    }
    return { value: getResult, result: true };
  } catch {
    return val;
  }
};

export const normalizeProp = (prop) =>
  typeof prop === "number"
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

const dec2hex = (dec) => {
  return dec.toString(16).padStart(2, "0");
};
export const generateRandomString = (len) => {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
};

export const getAddress = (
  address1,
  address2,
  city,
  cityArea,
  code,
  defaultVal
) => {
  let arr = [];
  if (address1 || address2) {
    const item = `${address1 || ""} ${address2 || ""}`.trim();
    arr.push(item);
  }
  if (city) {
    arr.push(city);
  }
  if (cityArea || code) {
    const item = `${cityArea || ""} ${code || ""}`.trim();
    arr.push(item);
  }
  if (arr.length) {
    return arr.join(", ");
  }
  return defaultVal || "- -";
};

export const getUserName = (firstName, lastName) => {
  let arr = [];
  if (firstName) {
    arr.push(firstName);
  }
  if (lastName) {
    arr.push(lastName);
  }
  return arr.length ? arr.join(" ") : "";
};

export const formatPhone = (str, defaultVal = "N/A") => {
  if (!str) {
    return defaultVal;
  }
  if (typeof str === "string") {
    const phone = parsePhoneNumber(str, "US");
    return phone?.formatInternational() || defaultVal;
  }
  return str;
};
