import React from "react";
import { Button, Result } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const PageContainer = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  background-color: var(--background-color);
  color: var(--text-main-color);
  padding: 10px 16px;
  height: 100vh;
`;

const PageNotFound = () => {
  const navigate = useNavigate();

  const toHome = () => {
    navigate("/dashboard/financial/earning");
  };

  return (
    <PageContainer>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={toHome}>
            Back Home
          </Button>
        }
      />
    </PageContainer>
  );
};

export default PageNotFound;
