import { useRef } from "react";
import { useSelector } from "react-redux";

export const usePermission = () => {
  const permissionRef = useRef(false);

  const user = useSelector((state) => state.user.user);
  permissionRef.current = user?.role?.includes("admin") || false;

  return { isPermission: permissionRef.current };
};
