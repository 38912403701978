import { createSlice } from "@reduxjs/toolkit";
import { readUser } from "@/services/localStorage.service";

const initialState = {
  user: readUser(),
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export default userSlice.reducer;
export const { updateUser } = userSlice.actions;
