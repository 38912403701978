import React, { useMemo } from "react";
import { Avatar, Dropdown } from "antd";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const items = [
  {
    key: "1",
    icon: <SettingOutlined />,
    label: <Link to="/setting">setting</Link>,
  },
  {
    key: "2",
    icon: <LogoutOutlined />,
    label: <Link to="/logout">logout</Link>,
  },
];

const ProfileDropdown = () => {
  const user = useSelector((state) => state.user.user);
  const firstUpperName = useMemo(
    () => user?.last_name?.slice(0, 1)?.toUpperCase(),
    [user]
  );
  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <Avatar alt="User">{firstUpperName}</Avatar>
    </Dropdown>
  );
};

export default ProfileDropdown;
