import React from "react";
import { Layout, Typography, Row, Space } from "antd";
import styled from "styled-components";
const { Footer } = Layout;
const { Text } = Typography;

const FooterText = styled(Text)`
  font-size: 0.75rem;
`;

const MainFooter = () => {
  return (
    <Footer id="main-layout-footer">
      <Row wrap={true} align="middle" justify="center">
        <Space direction="vertical" size={0} align="center">
            <FooterText type="secondary">
            © Copyright Rocket
            </FooterText>
            <FooterText type="secondary">
            All rights reserved by Rocket.shop Inc
            </FooterText>
        </Space>
      </Row>
    </Footer>
  );
};

export default MainFooter;
