import React from "react";
import { LoginForm } from "@/components/auth/LoginForm/LoginForm";
import { PageTitle } from "@/components/common/PageTitle/PageTitle";

function Login() {
  return (
    <>
      <PageTitle />
      <LoginForm />
    </>
  );
}

export default Login;
