import React from "react";
import { Col, Row } from "antd";
import ProfileDropdown from "./Profile";
import * as S from './MainHeader.style';
import Logo from "@/components/logo/Logo";

const MainHeader = () => {
  return (
    <S.MainHeader>
      <Row justify="space-between" align="middle">
        <Col span={12}>
          <Row align="middle">
            <S.ItemCol>
              <Logo />
            </S.ItemCol>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end" align="middle">
            <S.ItemCol>
              <ProfileDropdown />
            </S.ItemCol>
          </Row>
        </Col>
      </Row>
    </S.MainHeader>
  );
};

export default MainHeader;
