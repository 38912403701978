import React from "react";
import { App as AntApp } from "antd";
import { ConfigProvider } from "antd";
import { HelmetProvider } from "react-helmet-async";
import AppRouter from "./routes";
import GlobalStyle from "./styles/GlobalStyle";
import { themeObject } from "./styles/themes/themeVariables";

function App() {
  return (
    <>
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: themeObject.light.primary,
              borderRadius: 4,
            },
          }}
        >
          <AntApp>
            <AppRouter />
          </AntApp>
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
