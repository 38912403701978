import React from "react";
import { BaseForm } from "@/components/common/forms/BaseForm/BaseForm";
import * as Auth from "@/components/layout/AuthLayout/AuthLayout.styles";

const CheckEmailForm = () => {
  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical">
        <Auth.FormTitle>Forgot Password?</Auth.FormTitle>
        <Auth.FormResult>
          Check your email for a link to reset your password.
        </Auth.FormResult>
      </BaseForm>
    </Auth.FormWrapper>
  );
};

export default CheckEmailForm;
